<template>
  <div>
    <Portal to="title">
      {{ $t('user_profile.my_account_text') }}
    </Portal>
    <h2 class="font-bold text-lg mb-30"> {{ userName }}</h2>
    <form class="mb-10" novalidate @submit.prevent="setUserNewPassword">
      <label>
        <h2 class="font-bold text-lg mb-10">
          {{ $t('user_profile.change_password_label_text') }}
        </h2>
        <span class="flex flex-col mb-2 mobile-l:flex-row">
          <div class="flex flex-col">
            <input v-model="userPassword" type="password"
              :placeholder="$t('user_profile.change_password_placeholder_text')"
              class="min-h-35 min-w-0 border border-gray-300 px-10 bg-white rounded mr-10 mb-10">
            <input v-model="repeatUserPassword" type="password"
              :placeholder="$t('user_profile.change_password_confirm_placeholder_text')"
              class="min-h-35 min-w-0 border border-gray-300 px-10 bg-white rounded mr-10">
          </div>
          <button type="submit" class="button button-secondary button-lg button-filled h-35 px-30 mb-0 mt-auto">
            {{ $t('generic_crud_labels.save') }}
          </button>
        </span>
        <SmoothReflow tag="strong" :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }"
          class="field-error">
          <Transition name="transition-fade-slow">
            <span v-if="requestErrorMessage.length">
              {{ requestErrorMessage }}
            </span>
          </Transition>
        </SmoothReflow>
        <small v-if="savePasswordSuccessMessage" class="text-success">
          {{ passwordSavedText }}
        </small>
      </label>
    </form>
    <form class="mb-10 mt-30" novalidate @submit.prevent="setUserNewName">
      <label>
        <h2 class="font-bold text-lg mb-10">
          {{ $t('user_profile.change_name_title') }}
        </h2>
        <span class="flex flex-col mb-2 mobile-l:flex-row">
          <input v-model="newUserName" type="text" :placeholder="$t('user_profile.change_name_label_text')"
            class="min-h-35 min-w-0 border border-gray-300 px-10 bg-white rounded mr-10">
          <button type="submit" class="button button-secondary button-lg button-filled h-35 px-30">
            {{ $t('generic_crud_labels.save') }}
          </button>
        </span>
        <small v-if="saveNameSuccessMessage" class="text-success">
          {{ name_saved_text }}
        </small>
      </label>
    </form>
    <form class="mb-10 mt-30 table-caption" novalidate>
      <label>
        <h2 class="font-bold text-lg mb-10">
          {{ $t('user_profile.select_language_label_text') }}
        </h2>
        <span>
          <UISelect v-model="selectedLanguage" :placeholder="$t('user_profile.select_language_placeholder_text')"
            :options="localeOptions" class="min-h-35 min-w-0 border border-gray-300 px-10 bg-white rounded mr-10 w-250"
            @change="savePreferredLocale" />
        </span>
        <small v-if="saveLanguageSuccessMessage" class="text-success">
          {{ language_saved_text }}
        </small>
      </label>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { heightReflowOptions } from '@/helpers/reflowOptions';
import SmoothReflow from '@/components/SmoothReflow';
import checkPassword from '@/helpers/functions/checkPassword';
import { availableLocales } from '@/i18n';
import UISelect from '@/components/UI/UISelect';
import { getUserDetails } from '@/helpers/firestoreRefs';

export default {
  components: {
    SmoothReflow,
    UISelect
  },

  data: () => ({
    heightReflowOptions: Object.freeze(heightReflowOptions),
    repeatUserPassword: '',
    userPassword: '',
    newUserName: '',
    requestErrorMessage: '',
    saveNameSuccessMessage: false,
    savePasswordSuccessMessage: false,
    saveLanguageSuccessMessage: false,
    selectedLanguage: '',
  }),

  computed: {
    ...mapGetters('user/auth', {
      userName: 'userName',
      userEmail: 'userEmail',
      userPreferredDisplayLocale: 'userPreferredDisplayLocale',
    }),
    localeOptions() {
      return availableLocales.map((locale) => ({
        value: locale,
        text: this.$t(`language_display_names.${locale}`),
      }));
    },
  },

  async created() {
    await getUserDetails(this.$store.state.user.auth.id)
      .get()
      .then(async (userSnapshot) => {
        this.selectedLanguage =
          (userSnapshot.data()).preferredDisplayLocale
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    setUserNewPassword() {
      this.requestErrorMessage = '';
      const passwordError = checkPassword(this.userPassword, this.userEmail, this.repeatUserPassword);
      if (passwordError) {
        this.requestErrorMessage = passwordError;
        setTimeout(() => {
          this.requestErrorMessage = '';
        }, 4000);
        return;
      }

      this.$store.dispatch('user/auth/updatePassword', this.userPassword)
        .then(() => {
          this.userPassword = '';
          this.savePasswordSuccessMessage = true;
          this.passwordSavedText = 'Ihr Passwort wurde gespeichert';

          setTimeout(() => {
            this.savePasswordSuccessMessage = false;
          }, 2000);
        })
        .catch((error) => {
          this.requestErrorMessage = error.message;
        });
    },
    setUserNewName() {
      this.$store.dispatch('user/auth/updateUserDisplayName', this.newUserName)
        .then(() => {
          this.newUserName = '';
          this.saveNameSuccessMessage = true;
          this.name_saved_text = this.$t('user_profile.option_saved_text');

          setTimeout(() => {
            this.saveNameSuccessMessage = false;
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    savePreferredLocale() {
      if (this.$route.params.locale === this.selectedLanguage) {
        this.savePreferredDisplayLocale(this.selectedLanguage);
        return;
      }

      this.$router.replace({
        params: {
          ...this.$route.params,
          locale: this.selectedLanguage,
        },
        query: this.$route.query,
      }).then(() => {
        this.$i18n.locale = this.selectedLanguage;
        this.savePreferredDisplayLocale(this.selectedLanguage);
      }).catch((error) => {
        console.error(error);
      });
    },

    savePreferredDisplayLocale(locale) {
      getUserDetails(this.$store.state.user.auth.id)
        .update({
          preferredDisplayLocale: this.selectedLanguage,
        })
        .then(() => {
          this.saveLanguageSuccessMessage = true;
          this.language_saved_text = this.$t('user_profile.language_saved_text');

          setTimeout(() => {
            this.saveLanguageSuccessMessage = false;
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
</style>
